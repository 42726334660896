import React from 'react';
import { graphql, Link } from 'gatsby';
import Helmet from 'react-helmet';
import classnames from 'classnames';

import AnchorLink from '../components/AnchorLink';
import { logos } from '../components/Bio';
import IconLink from '../components/IconLink';

import * as skills from '../data/skills-fr';
import * as experiences from '../data/experience-fr';

import portrait from '../images/resume-pic.jpg';
import frFlag from '../images/flags/fr.svg';
import gbFlag from '../images/flags/gb.svg';


const description = `\
Passionate, highly autonomous developer with a love for exploration, \
attention to detail and a taste for sharing knowledge. \
Fervent about crafting software tools that empower humans, \
insistent they should be open, solid and dependable.`;

const translation = (name, url, flag) => (
    <Link to={url} className="translation">
        <img src={flag} className="flag" alt={name}/>
    </Link>
);


export default class ResumePage extends React.Component {
    render() {
        const siteMetadata = this.props.data.site.siteMetadata;
        const author = siteMetadata.author;
        const { key } = this.props.pageContext;
        const { resume, education, interests } = this.props.data;

        return (
            <>
                <Helmet title={`CV | ${siteMetadata.title}`}>
                    <html lang="fr"/>
                    <link rel="canonical" href={`${siteMetadata.siteUrl}resume/${key}`} />
                    <meta name="description" value={`CV — Julien Hartmann`} />
                    <meta name="author" value="Julien Hartmann" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="CV — Julien Hartmann" />
                    <meta property="og:site_name" content={siteMetadata.title} />
                    <meta property="og:description" content={description} />
                    <meta property="og:image" content={portrait} />
                    <meta property="twitter:site:id" content={siteMetadata.twitterId} />
                    <link rel="alternate" type="application/json+oembed" href="/oembed.json" />
                </Helmet>
                <main className="resume-page"><article className="resume">
                    { resume.enlink && <nav className="translations">
                        {translation('English', resume.enlink, gbFlag)}
                        {translation('French', `resume/${key}`, frFlag)}
                    </nav> }
                    { resume.title && <h1>{resume.title}</h1> }
                    <div className="content">
                    { resume.variant == 'itexpert' &&
                        <p>Fort de multiples expériences dans de grandes entreprises et divers
                            secteurs, je crois dans une approche globale du SI,
                            dotée d’une vision long terme au service du métier.</p>
                    }
                    <div className="side-by-side abstract">
                        <p className="side">
                            <b>Ingénieur logiciel</b> passionné et autonome, avec un amour pour
                            l'exploration, un sens aigu du détail et un goût pour la transmission
                            du savoir. J'aime par dessus tout créer des
                            outils <strong>ouverts</strong>, <strong>fiables</strong>{' et '}
                            <strong>extensibles</strong>, donnant aux utilisateurs
                            de nouvelles possibilités.</p>
                        <p className="side">
                            <b>Architecte IT</b> confirmé avec 8 années d'expérience de conception,
                            transformation et migration d'infrastructures de grande échelle, avec
                            une attention particulière aux <strong>problématiques
                            transverses</strong>, et
                            une <strong>approche systémique</strong> centrée sur les
                            besoins métier.</p>
                    </div>
                    { resume.variant == 'developer' &&
                        <p>Je souhaite combiner mon savoir-faire en analyse
                            et structuration de systèmes complexes, afin de concevoir des
                            solutions logicielles professionnelles proches des utilisateurs
                            finaux.</p>
                    }
                    <section className="skills">
                        <h2><span>Savoir-faire clés</span></h2>
                        {skills[resume.variant]()}
                    </section>
                    <section className="experience">
                        <h2><span>Expérience</span></h2>
                        {resume.experiences.map((key) => experiences[key](Experience))}
                    </section>
                    <section className="education">
                        <h2><span>Éducation</span></h2>
                        <span dangerouslySetInnerHTML={{ __html: education.html }} />
                    </section>
                    <section className="interests">
                        <h2><span>Centres d'intérêt</span></h2>
                        <span dangerouslySetInnerHTML={{ __html: interests.html }} />
                    </section>
                    </div>
                </article>
                <AnchorLink className="back-to-top" href="#">retour en haut</AnchorLink>
                </main>
                <aside className="resume-aside"><div className="sticky">
                    <section>
                        <img className="profile-picture" src={portrait} alt="Julien Hartmann" />
                        <div className="profile-name">Julien Hartmann</div>
                        { resume.contact && <Contact value={resume.contact} /> }
                        <ul className="s-links">{author.links.map(({name, url, title}, index) => (
                            <li key={index}><IconLink href={url} text={title} src={logos[name]} /></li>
                        ))}</ul>
                    </section>
                </div></aside>
            </>
        );
    }
}

const Contact = ({className, value}) => {
    const { phone, mail, location } = value;
    const phoneHref = phone && `tel:+${phone.replace(/\D/g, '')}`;
    return (<ul className={classnames('contact', className)}>
        { phone && <li className="phone"><a className="phone-link" href={phoneHref}>{phone}</a></li> }
        { mail && <li className="mail">{mail}</li> }
        { location && <li className="location">{location}</li> }
    </ul>);
};

const Experience = ({children, company, id, title}) => (
    <section className="resume-experience" id={id}>
        <header>
            <h3>{title}</h3>
            {company && <div className="company">
                {company.link ?
                    <a href={company.link} rel="external noopener noreferrer">
                        {company.name}
                        {company.logo && <img className="logo" src={company.logo} alt=""/> }
                    </a>
                : <> {company.name} </>}
            </div>}
        </header>
        <main>{children}</main>
    </section>
);

export const pageQuery = graphql`
query ResumeByKeyFR($key: String!) {
    resume: resumeYaml(key: { eq: $key }) {
        title
        variant
        enlink
        contact { mail location }
        experiences
    }
    site {
        siteMetadata {
            title
            siteUrl
            twitterId
            author {
                links { url name title }
            }
        }
    }
    education: markdownRemark(frontmatter: {chunk: {eq: "resume-education-fr"}}) { html }
    interests: markdownRemark(frontmatter: {chunk: {eq: "resume-interests-fr"}}) { html }
}
`
