import React from 'react';
import { Link } from 'gatsby';


export const developer = () => (<>
    <div className="side-by-side">
        <dl className="side">
            <dt>Langages essentiels</dt>
            <dd>C++20, Python 3, JavaScript ES9.</dd>
            <dt>Technologies</dt>
            <dd>
                Experience approfondie en :
                <ul><li>
                    programmation système, réseau, embarqué, bases de données, web.
                </li>
                <li>DevOps, intégration continue (CI/CD), conteneurisation (Docker)</li>
                <li>Infrastructure-as-Code (Ansible, Packer)</li>
                </ul>
            </dd>
            <dt>Architecture</dt>
            <dd>
                Compétent en :
                <ul>
                <li>Communication de concepts complexes aux stakeholders métier.</li>
                <li>Conception d'architectures logicielles évolutives et maintenables.</li>
                <li>Mise en œuvre de modèles d'architecture courants.</li>
            </ul></dd>
        </dl>
        <dl className="side">
            <dt>Leadership</dt>
            <dd>
                Compétences en :
                <ul>
                <li>Leadership technique et coordination.</li>
                <li>Mentorat et développement des talents.</li>
                <li>Innovation et amélioration continue.</li>
            </ul></dd>

            <dt>Craftsmanship</dt>
            <dd><ul>
                <li>Autodidacte, sachant synthétiser et transmettre.</li>
                <li>Approche systémique de la résolution de problèmes.</li>
                <li>Goût prononcé pour les solutions élégantes.</li>
            </ul></dd>
            <dt>Outils de consulting</dt>
            <dd>
                Équipé de:
                <ul>
                <li>Capacités de coaching et formation.</li>
                <li>Solides compétences en présentation et communication.</li>
            </ul></dd>
        </dl>
    </div>
    <p className="remark">
        ➥ Mon expérience en infrastructure et opérations IT est un
          atout pour la conception de logiciels. Voir <Link to="/resume/Architecte-IT">CV</Link>.</p>
</>);

export const itexpert = () => (<>
    <div className="side-by-side">
        <dl className="side">
            <dt>Transformation</dt>
            <dd><ul>
                <li>Étude d'opportunité / estimation de ROI.</li>
                <li>Conception d'architecture IT / DAT.</li>
                <li>Planification et coordination des migrations.</li>
                <li>Audits / investigation des problèmes.</li>
            </ul></dd>
            <dt>Opérations</dt>
            <dd><ul>
                <li><strong>Processus :</strong> gestion des changements, capacity planning,
                    sauvegarde et restauration, monitoring, supervision, gestion des incidents.</li>
                <li>Bonne expérience du day-to-day.</li>
                <li>Certification ITIL Foundation.</li>
            </ul></dd>
        </dl>
        <dl className="side">
            <dt>Infrastructure</dt>
            <dd><ul>
                <li><strong>Architecture orientée service</strong> (SOI).</li>
                <li><strong>Systèmes :</strong> GNU/Linux, Windows.</li>
                <li><strong>Réseau :</strong> modèle OSI, firewalls, load balancers, WAN optimizers.</li>
                <li><strong>Stockage :</strong> SAN, NAS, iSCSI.</li>
                <li><strong>Virtualisation:</strong> VMware (certifié), XenServer, Hyper‑V.</li>
            </ul></dd>
            <dt>Outils de conseil</dt>
            <dd><ul>
                <li>Écoute et pensée analytique.</li>
                <li>Coaching et formation.</li>
                <li>Excellente capacité rédactionnelle.</li>
            </ul></dd>
        </dl>
    </div>
    <p className="remark">
        ➥ Ma longue expérience du développement logiciel est aussi un atout
        pour l'architecture IT. Voir le <Link to="/resume/fr">CV</Link>.</p>
</>);
