import React from 'react';
import { Link } from 'gatsby';

import keyledsLogo from '../images/resume-keyleds.png';
import botcryptoLogo from '../images/resume-botcrypto.png';
import hvadLogo from '../images/resume-django.png';
import groundlinkLogo from '../images/resume-groundlink.png';
import wavestoneLogo from '../images/resume-wavestone.png';

import amfLogo from '../images/resume-amf.png';
import bnpLogo from '../images/resume-bnp.png';
import carrefourLogo from '../images/resume-carrefour.png';
import casaLogo from '../images/resume-casa.png';
import laposteLogo from '../images/resume-laposte.png';
import totalLogo from '../images/resume-total.png';


const formatList = (data, listClass, itemClass) => (
    <ul className={listClass}>{data.map(str => <li className={itemClass}>{str}</li>)}</ul>
);


export const keyleds = (Experience) => (
    <Experience id="keyleds"
                title="Reverse-engineering – développement de driver"
                company={{ name: 'keyleds', logo: keyledsLogo,
                            link: 'https://github.com/keyleds/keyleds'}}>
        <p className="statement">
            Projet OpenSource, implémentant le support Linux complet des claviers Logitech RGB,
            y compris les effets dynamiques en réponse aux actions de l'utilisateur.
        </p>
        <p><ul>
            <li className="action"><strong>Reverse-engineering</strong> du protocole propriétaire
                via capture des échanges USB.</li>
            <li className="action"><strong>Réalisation</strong> d'une couche d'abstraction
                matérielle et d'une <b>interface Python</b>.</li>
            <li className="action"><strong>Conception</strong> et <strong>implémentation</strong>
                {' d\'un '}<b>service</b> s'appuyant sur une architecture
                en <b>plugins</b> et un <b>interpréteur LUA</b> afin que les utilisateurs
                puissent écrire leurs propres animations.</li>
            <li className="action"><strong>Optimisation</strong> des chemins de code critiques
                en utilisant la <b>vectorisation SIMD</b>.</li>
            <li className="action"><strong>Integration</strong> avec l'écosystème bureau Linux
                pour une bonne expérience utilisateur. Ex. : detection du branchement d'un
                nouveau périphérique, du verrouillage de la session, …</li>
        </ul></p>
        <p>{formatList(['WireShark', 'VirtualBox', 'CMake', 'Cython', 'C++17', 'LuaJIT', 'HID protocol'], 'tools', 'tool')}</p>
    </Experience>
);

export const botcrypto = (Experience) => (
    <Experience id="botcrypto"
                title="Architecture logicielle"
                company={{ name: 'botcrypto.io', logo: botcryptoLogo,
                            link: 'https://botcrypto.io/'}}>
        <p className="statement">
            Botcrypto s'apprêtait à mettre en ligne une solution de trading automatisée ciblant
            les investisseurs particuliers. Mais les tests montraient des problèmes de performance
            suffisamment importants pour bloquer le lancement.
        </p>
        <p><ul>
            <li className="action">Identification des <strong>contentions</strong> et <strong>analyse
                des causes</strong>.</li>
            <li className="action"><strong>Redesign</strong> de l'architecture sur un modèle
                en <b>microservices</b>.</li>
            <li className="action">Remplacement des outils maison par les standards du marché.</li>
            <li className="action">Suivi du développement en tant qu'<b>architecture advisor</b>,
                participant aux décisions d'architecture et aux revues périodiques.</li>
            <li className="result">La solution redesignée présente une <strong>multiplication par
                1000 de sa capacité de traitement</strong>, à matériel équivalent.
            </li>
        </ul></p>
        <p>{formatList(['Python 3', 'asyncio', 'Docker', 'PostgreSQL', 'redis', 'websockets'], 'tools', 'tool')}</p>
    </Experience>
);

export const hvad = (Experience) => (
    <Experience id="hvad"
                title="Refonte et maintenance"
                company={{ name: 'django-hvad', logo: hvadLogo,
                            link: 'https://github.com/KristianOellegaard/django-hvad'}}>
        <p className="statement">
            Un plugin pour Django, avec une large base d'utilisateurs et un développement à
            l'arrêt nécessitait un nettoyage et une mise à jour, centrés sur deux problèmes
            de fond :
        </p>
        <p><ul>
            <li className="issue">Les incompatibilités avec les nouvelles versions de Django
                introduisaient une <b>dette technique</b> inmaintenable.
            <ul>
                <li className="action"><strong>Circonscription</strong> des couplages
                    forts, <strong>abstraction</strong> des fonctionnalités portées par les
                    dépendances et <strong>encapsulation</strong> des incompatibilités.</li>
                <li className="result">Après la refonte, <strong>le nombre moyen de modules
                    affectés par une release a été divisé par 3.</strong></li>
            </ul></li>
            <li className="issue">Des incohérences dans l'API publique, exposée à une grande
                quantité d'utilisateurs rendaient les évolutions difficiles.
            <ul>
                <li className="action"><strong>Conception</strong> d'une nouvelle API avec un accent
                    sur l'utilisabilité et l'orthogonalité.</li>
                <li className="action">Mise en œuvre d'une <strong>dépréciation progressive</strong> de
                    l'ancienne API sur plusieurs versions.</li>
                <li className="result">La transition n'a causé <strong>aucune augmentation
                    du nombre de tickets ouverts.</strong></li>
            </ul></li>
        </ul></p>
        <p>{formatList(['Python 3', 'Django', 'Django-REST-framework', 'Travis-CI'], 'tools', 'tool')}</p>
    </Experience>
);

export const etherinfra = (Experience) => (
    <Experience id="etherinfra"
                title="Automatisation de déploiement et intégration"
                company={{ name: 'n/c' }}>
        <p className="statement">
            Un consultant indépendant utilisant des services personnalisés (dont applications 3-tier,
            VPN, personal cloud storage) pour son activité souhaitait s'assurer de la disponibilité
            de ses outils de travail et de la sécurité de ses données, dans des contraintes
            budgétaires serrées.
        </p>
        <p><ul>
            <li className="action"><strong>Identification</strong>{' des services, '}
                <strong>formalisation</strong> de l'architecture et des configurations.</li>
            <li className="action">Mise en œuvre d'une solution d'<b>automatisation</b> des
                déploiement pour reconstruire les environnements en cas de défaillance,
                éliminant les besoins en redondance.</li>
            <li className="action"><strong>Classification</strong> des données, mise en place
                de chiffrement et de sauvegardes et <strong>développement</strong> des scripts
                ad-hoc.</li>
            <li className="result">Lors d'une défaillance majeure en 2016, tous <strong>les services
                étaient disponibles en moins d'une heure</strong> après le délenchement du plan
                de reprise, et <strong>aucune donnée ne fût perdue.</strong>.</li>
        </ul></p>
        <p>{formatList(['Python', 'Ansible', 'OpenVPN', 'PostgreSQL'], 'tools', 'tool')}</p>
    </Experience>
);

export const groundlink = (Experience) => (
    <Experience id="groundlink"
                title="Conception et mise en œuvre d'une infrastructure Hyper-V"
                company={{ name: 'GroundLink', logo: groundlinkLogo,
                            link: 'https://www.groundlink.com/'}}>
        <p className="statement">
            Afin de <strong>rationaliser</strong> le cycle de release de ses développements
            internes et réduire le time-to-market, GroundLink a décidé d'introduire des
            environnements de développement staging et pré-production dédiés et isolés pour
            ses équipes en Serbie. Pour réduire les coûts et gagner en agilité, la migration
            vers une infrastructure virtualisée a été choisie. J'ai réalisé :
        </p>
        <p><ul>
            <li className="action"><strong>Analyse de l'existant</strong>{' et '}
                <strong>profiling</strong> des applications sur l'infrastructure physique.</li>
            <li className="action"><strong>Conception</strong>{' et '}
                <strong>dimensionnement</strong> de l'infrastructure cible, prenant en compte
                les projections de croissance et les évolutions technologiques.</li>
            <li className="action"><strong>Coordination</strong> du déploiement et de la migration
                avec la maison mère à New York.</li>
            <li className="action"><strong>Formation</strong> des équipes locales au nouvel
                environement.</li>
            <li className="result">La migration réussie des applications éligibles
                a <strong>réduit les coûts de 80%</strong>, tout en fournissant la séparation
                des environnements attendue.</li>
        </ul></p>
        <p>{formatList(['Hyper-V', 'PowerShell', 'iSCSI'], 'tools', 'tool')}</p>
    </Experience>
);

export const solucom = (Experience) => {
    const renderClient = (name, logo, url) => (
        <li className="client">
            <a href={url} rel="external noopener noreferrer" target="_blank">
                <img className="client-logo" src={logo} alt={name} />
            </a>
        </li>
    );
    return (
    <Experience id="solucom"
                title="Consultant en Architecture IT"
                company={{ name: 'Wavestone', logo: wavestoneLogo,
                            link: 'https://www.wavestone.com/'}}>
        <p className="statement">
            <strong>Expert infrastructure</strong> spécialisé sur les <b>problématiques transverses</b>
            , la <b>rationalisation</b> and la <b>virtualisation</b> serveur.
            Un total de 19 missions menées à bien entre 2005 et 2011.
        </p>
        <p>J'ai travaillé essentiellement aux transformations résultant de l'introduction de
            la virtualisation x86 dans les datacenters, tant sur les aspects techniques
            qu'organisationnels.</p>
        <p>Des missions très diverses, incluant :
        <ul className="missions"><li>
            Veille technologique, études d'opportunité, estimations de ROI, schéma directeur
            des infrastructures, architecture technique du SI, planification et coordination de
            migrations, audits, diagnostics et recherche de root cause,
            développement d'outils spécifiques.
        </li></ul></p>
        <p><ul className="clients">
            {renderClient('Autorité de Marchés Financiers', amfLogo, 'https://www.amf-france.org/')}
            {renderClient('BNP Paribas', bnpLogo, 'https://group.bnpparibas/')}
            {renderClient('Carrefour', carrefourLogo, 'http://www.carrefour.com/')}
            {renderClient('Crédit Agricole SA', casaLogo, 'https://www.credit-agricole.com/en/')}
            {renderClient('La Poste', laposteLogo, 'https://www.laposte.fr/')}
            {renderClient('Total Petrochemicals', totalLogo, 'https://www.total.com/')}
        </ul></p>
        <p>{formatList(['ITIL (Foundation-certified)', 'Service-Oriented Infrastructure'], 'tools', 'tool')}</p>
        <p>➥ Pour le détail de mon expérience en tant qu'architecte IT, veuillez voir
            mon <Link to="/resume/Architecte-IT">autre CV</Link>.</p>
    </Experience>
    );
};

export const solucomalt = (Experience) => (
    <Experience id="solucom"
                title="Consultant en Architecture IT"
                company={{ name: 'Wavestone', logo: wavestoneLogo,
                           link: 'https://www.wavestone.com/'}}>
        <p className="statement">
            <strong>Expert infrastructure</strong> spécialisé sur les <b>problématiques transverses</b>
            , la <b>rationalisation</b> et la <b>virtualisation</b> serveur.
            Un total de 19 missions menées à bien entre 2005 et 2011.
        </p>
        <p>J'ai travaillé essentiellement aux transormations résultant de l'introduction de
            la virtualisation x86 dans les datacenters, tant sur les aspects techniques
            que organisationnels.</p>
        <p>Des missions très diverses, incluant :
        <ul className="missions"><li>
            Veille technologique, études d'opportunité, estimations de ROI, schéma directeur
            des infrastructures, architecture technique du SI, planification et coordination de
            migrations, audits, diagnostics et recherche de root cause,
            développement d'outils spécifiques.
        </li></ul></p>
        <p><strong>➥ Les items suivants sont des missions choisies, pour le
            compte de Wavestone.</strong></p>
    </Experience>
);

export const amf = (Experience) => (
    <Experience id="amf"
                title="Structuration des opérations"
                company={{ name: 'Autorité des Marchés Financiers', logo: amfLogo,
                           link: 'https://www.amf-france.org/' }}>
        <p className="statement">
            Après la crise des subprimes de 2008, l'AMF a vu son champ d'action et son budget
            significativement étendus, conduisant à une multiplication par 10 de l'activité
            du département IT. Son organisation en a été impactée en profondeur, et requérait
            une restructuration et une industrialisation.</p>
        <p><ul>
        <li className="action"><strong>Évaluation</strong> des processus d'opérations existants
            par une série d'interviews.</li>
        <li className="action"><strong>Mise en œuvre</strong> de best practices tirées de <b>ITIL</b>, en
            appliquant un processus d'<b>amélioration continue</b>. Particulièrement autour de
            la <b>gestion du changement</b>, de la <b>supervision</b> et de la <b>gestion des
            incidents</b>.</li>
        <li className="action"><strong>Accompagnement</strong> des responsables et facilitation
            de la communication avec les équipes.</li>
        <li className="action"><strong>Mise en place</strong>{' d\'un '}
            <a href="https://en.wikipedia.org/wiki/Change-advisory_board" rel="external noopener" target="_blank">CAB
            </a>, assurant la visibilité des changements, de leurs risques et de leurs
            dépendances.</li>
        </ul></p>
        <p>{formatList(['ITIL', 'IWS Qualities', 'Nagios'], 'tools', 'tool')}</p>
    </Experience>
);

export const total = (Experience) => (
    <Experience id="total"
                title="Consolidation de sites distants"
                company={{ name: 'Total', logo: totalLogo,
                           link: 'https://www.total.com/' }}>
        <p className="statement">
            Afin de réduire ses coûts IT, Total Petrochemicals
            désirait concentrer sur un site unique les applications réparties sur ses multiples
            sites distants en Europe.</p>
        <p><ul>
            <li className="action"><strong>Inventaire</strong> applicatif, incluant
                la <b>cartographie des flux</b> et les <b>profils de consommation</b>.</li>
            <li className="action"><strong>Conception</strong> de l'architecture cible, tant
                pour l'hébergement que pour le WAN.</li>
            <li className="action"><strong>Communication</strong> avec les sites distants pour créer
                les <b>procédures</b> et <b>outils</b> facilitant la migration.</li>
            <li className="action"><strong>Coordination</strong> des sites et des fournisseurs
                pour minimiser l'impact métier de la migration.</li>
            <li className="action"><strong>Déploiement</strong> des accélérateurs WAN et
                déplacement physique des composants applicatifs.</li>
            <li className="result">Le déploiement a été achevé dans les
                délais, <strong>réduisant les coûts d'hébergement de 70%</strong>.</li>
        </ul></p>
        <p>{formatList(['BladeCenter', 'Riverbed', 'SAN', 'VMware'], 'tools', 'tool')}</p>
    </Experience>
);

export const casa = (Experience) => (
    <Experience id="casa"
                title="Veille technologique"
                company={{ name: 'Crédit Agricole', logo: casaLogo,
                           link: 'https://www.credit-agricole.com/en/' }}>
        <p className="statement">
            En préparation d'un plan de consolidation d'un SI de 20 000 serveurs répartis
            dans plusieurs filiales, Crédit Agricole souhaitait définir des bonne pratiques
            à l'échelle du groupe pour les infrastructures virtualisées, en s'appuyant sur
            l'état de l'art du marché ainsi que les tendances émergentes.</p>
        <p><ul>
            <li className="action"><strong>Analyse</strong> des pratiques existantes au sein
                des différentes entités du groupe.</li>
            <li className="result"><strong>Synthèse</strong> et <strong>animation
                d'ateliers</strong> afin d'identifier les synergies et points d'attention.</li>
            <li className="action"><strong>Formalisation</strong> d'une approche homogène
                tenant compte des enjeux liés à la virtualisation et son interaction avec
                les autres éléments du SI au sein du groupe CASA.</li>
        </ul></p>
        <p>{formatList(['Hyper-V', 'VMware', 'XenServer', 'SAN', 'NAS', 'iSCSI', 'vSwitch'], 'tools', 'tool')}</p>
    </Experience>
);

export const laposte = (Experience) => (
    <Experience id="laposte"
                title="Virtualisation datacenter"
                company={{ name: 'La Poste', logo: laposteLogo,
                           link: 'https://www.laposte.fr/' }}>
        <p className="statement">
            Au cours de son évolution technologique de routine, La Poste a décidé d'évaluer
            l'opportunité de virtualiser une infrastructure de 250 serveurs. J'ai réalisé :</p>
        <p><ul>
            <li className="action"><strong>Étude d'éligibilité</strong>, incluant
                une analyse du parc sur des critères techniques et organisationnels.</li>
            <li className="action"><strong>Conception</strong> de plusieurs scénarios de
                transformation, avec <b>chemin de migration</b>, <b>perspectives de
                croissance</b>, <b>analyse des risques</b> et <b>estimations de ROI</b>.</li>
            <li className="action"><strong>Définition</strong> de l'architecture cible
                détaillée après la sélection d'un scénario par les responsables.</li>
            <li className="action"><strong>Recette</strong> de l'implémentation par un <b>audit
                technique</b>.</li>
            <li className="result">La transformation a atteint le <strong>point d'équilibre
                sous 18 mois</strong> tout en <strong>divisant le TCO par 6</strong>.</li>
        </ul></p>
        <p>{formatList(['VMware'], 'tools', 'tool')}</p>
    </Experience>
);

export const c4b2b = (Experience) => (
    <Experience id="c4b2b"
                title="Investigation d'un problème de connectivité à l'international"
                company={{ name: 'Carrefour', logo: carrefourLogo,
                           link: 'http://www.carrefour.com/' }}>
        <p className="statement">
            Des problèmes de connectivité récurrents avec certains partenaires B2B à l'étranger
            dégradaient les performances et provoquaient des pertes de données métier régulières.</p>
        <p><ul>
            <li className="action"><strong>Mise en place</strong> de captures au niveau du
                backbone datacenter, et définition d'heuristiques pour discriminer les données
                pertinentes.</li>
            <li className="action"><strong>Analyse</strong> du problème : une non-conformité
                d'un équipement réseau se combinait à des conditions externes dans certaines
                circonstances pathologiques.</li>
            <li className="result">Après l'application de la solution de
                contournement, <strong>les pertes de données cessèrent</strong> définitivement.</li>
        </ul></p>
        <p>{formatList(['Cisco Catalyst', 'Python', 'WireShark'], 'tools', 'tool')}</p>
    </Experience>
);

export const c4bench = (Experience) => (
    <Experience id="c4bench"
                title="Analyse de performance en environnement virtualisé"
                company={{ name: 'Carrefour', logo: carrefourLogo,
                           link: 'http://www.carrefour.com/' }}>
        <p className="statement">
            Premier datacenter virtualisé en Europe. Un problème de performance bloque le lancement
            d'une application, conduisant les responsables à menacer l'arrêt complet du projet :
            les instances Oracle ont des temps de réponse catastrophiques sous VMware.</p>
        <p><ul>
            <li className="issue">Oracle est un système propriétaire, et l'éditeur refuse
                tout support en environnement virtuel.
            <ul>
                <li className="action"><strong>Analyse</strong> du comportement applicatif
                    au niveau système à l'aide d'outils de debugging
                    et <strong>identification</strong> des patterns problématiques.</li>
            </ul></li>
            <li className="issue">Être early adopter implique qu'il n'existe aucune modélisation
                de la charge serveur en environnement virtuel.
            <ul>
                <li className="action"><strong>Isolation</strong> des comportements problématiques,
                    puis <strong>instrumentation</strong> en créant des jeux de tests et
                    de <b>benchmarks</b> autonomes.</li>
            </ul></li>
            <li className="result"><strong>Identification</strong> de l'origine réelle du
                problème et résolution, mettant l'infrastructure hors de cause.</li>
            <li className="result">L'application a pu être mise en production, <strong>évitant
                la perte de confiance et l'arrêt</strong> de tout le projet d'infrastructure
                virtuelle.</li>
        </ul></p>
        <p>{formatList(['C99', 'Oracle 9i', 'VMware ESX'], 'tools', 'tool')}</p>
    </Experience>
);

